/* eslint-disable react-hooks/rules-of-hooks */
import { useIsomorphicLayoutEffect } from '@socar-inc/hooks'
import { isAndroidDevice } from '@socar-inc/utils'
import Head from 'next/head'
import { Fragment, useState, type ReactNode } from 'react'

export const withHeadTitle = (component: ReactNode, title: string = '') => {
  const [defaultTitle, setDefaultTitle] = useState(() => title)

  useIsomorphicLayoutEffect(() => {
    setDefaultTitle((prev) =>
      isAndroidDevice(navigator.userAgent) ? 'SOCAR' : prev
    )
  }, [])

  return (
    <Fragment>
      <Head>
        <title>{title ?? defaultTitle}</title>
      </Head>
      {component}
    </Fragment>
  )
}
